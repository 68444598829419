<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import helpers from '../../../helpers'
import Earnings from "@/components/dashboard/earnings";
import FilterC from "@/components/dashboard/live-shopping/filter"
import Drawer from "vue-simple-drawer";

import {
  liveshoppingAnalyticsMethods,
} from "@/state/helpers";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Go Live Analytics",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    DatePicker,
    Earnings,
    FilterC,
    Drawer
  },
  data() {
    return {
      title: this.$t('kpi.title'),
      items: [],
      
      showModal: false,
      isLoading: false,
      fullPage: true,
      canCancel: false,
      query: '',
      user: JSON.parse(localStorage.getItem("auth.currentUser")).user,
      dateFilter: null,
      disabledBefore: new Date().setDate(new Date().getDate() - 90),
      shortcuts: [
        {
          text: this.$t('kpi.filter_date_today'),
          onClick() {
            const start = new Date();
            const end = new Date();
            
            end.setTime(end.getTime() + 0 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_yesterday'),
          onClick() {
            
            const start = new Date();
            const end = new Date();
            
            start.setTime(start.getTime() -1 * 24 * 3600 * 1000);
            const date = [start, end];
            
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_week'),
          onClick() {
            
            var curr = new Date; // get current date
            var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
            var last = first + 6; // last day is the first day + 6

            var firstday = new Date(curr.setDate(first));
            var lastday = new Date(curr.setDate(last));
            
            const date = [firstday, lastday];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_month'),
          onClick() {
            var today = new Date; // get current date
            const firstday = new Date(today.getFullYear(), today.getMonth(), 1);
            //const lastday = new Date(today.getFullYear(), today.getMonth() + 1, 0);

            const date = [firstday, today];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_week'),
          onClick() {
            const today = new Date();
            const lastWeek = new Date(today);
            lastWeek.setDate(today.getDate() - 7); // Resta 7 días para obtener la semana pasada

            // Obtén el primer día de la semana pasada (domingo)
            const firstDay = new Date(lastWeek);
            firstDay.setDate(lastWeek.getDate() - lastWeek.getDay());

            // Obtén el último día de la semana pasada (sábado)
            const lastDay = new Date(lastWeek);
            lastDay.setDate(lastWeek.getDate() - lastWeek.getDay() + 6);

            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_month'),
          onClick() {
            const today = new Date();
  
            // Calculate the first day of the last month
            const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
  
            // Calculate the last day of the last month
            const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
            const date = [firstDay, lastDay];

            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_7_days'),
          onClick() {
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 6); // First day is 7 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
        {
          text: this.$t('kpi.filter_date_this_last_30_days'),
          onClick() {
            
            const today = new Date();
            const lastDay = new Date(today); // Last day is today
            const firstDay = new Date(today);
            firstDay.setDate(today.getDate() - 29); // First day is 30 days ago

            const date = [firstDay, lastDay];
            return date;
          },
        },
      ],

      isLocal: window.config.local ? true : false,
      totalEvents : 0,
      completedEvents : 0,
      totalMinutes : 0,
      avgMinutes:0,
      openRightDrawer: false,
      campaignId: null,
      filters:[]
      
    };
  },
  mounted() {
    const today = new Date();
    const lastDay = new Date(today); 
    const firstDay = new Date(today);
    firstDay.setDate(today.getDate() - 29); 
    
    this.dateFilter = [firstDay, lastDay]; 

  },
  methods:{
    ...helpers,
    ...liveshoppingAnalyticsMethods,

    getCustomFilterQuery(){
      let q = '';
      if(this.filters.length>0){
        this.filters.forEach((f)=>{
          q+= `&${f.key}=${f.value}`
        });
      }

      return q;
    },

    onRemoveFilter(filter){
      this.filters = this.filters.filter(f=> f.key != filter.key);
      
      if(this.filters.length == 0)
        this.personalizationId = null;

      const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;

      this.applyFilter(query)
      
    },

    applyCustomFilter(){
      const nextDay = new Date(this.dateFilter[1]);
      nextDay.setDate(this.dateFilter[1].getDate() + 1)

      let query = `project=${localStorage.getItem('current_project')}`
      query+= `&dateFrom=${this.formatDate(this.dateFilter[0])}&dateTo=${this.formatDate(nextDay)}`;
      
      this.filters = this.$refs.filterRef.getAllFilters();
      
      this.applyFilter(query)
     
        this.openRightDrawer = false;
    },

    removeAllFilter(){
      this.$refs.filterRef.removeAll();
    },

    rightDrawer(){
      this.openRightDrawer = false;
    },

    onFilterClicked(){
      this.openRightDrawer=true; 
      setTimeout(()=>{
        this.$refs.filterRef.setAllFilter(this.filters)
      },1000)
    },

    
    formatDate(d) {
      
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) 
        month = '0' + month;
      if (day.length < 2) 
        day = '0' + day;
      
      return [year, month, day].join('-');
    },
    
    applyFilter(query) {
      
      if(this.filters.length > 0){
        query+= this.getCustomFilterQuery();
      }

      this.query = query;

      this.getEventsGeneralStats(query).then((res)=>{
        if(res && res.data){
          this.totalEvents = res.data.filter(a=> a.label == "total_events").length !=0 ? res.data.filter(a=> a.label == "total_events")[0].count : 0;
          this.completedEvents = res.data.filter(a=> a.label == "total_events_closed").length !=0 ? res.data.filter(a=> a.label == "total_events_closed")[0].count : 0;
          this.totalMinutes = res.data.filter(a=> a.label == "total_minutes").length !=0 ? res.data.filter(a=> a.label == "total_minutes")[0].count : 0;
          this.avgMinutes = res.data.filter(a=> a.label == "avg_minutes").length !=0 ? Math.round(parseFloat(res.data.filter(a=> a.label == "avg_minutes")[0].count)) : 0;
        }
      }).catch((ex)=>{
        // eslint-disable-next-line no-console
        console.log(ex);
      })

      this.getEventsGeneralStatsLastSyncDate(query).then((res)=>{
        if(res && res.data && res.data.last_replication_date){
          this.lastSyncDate = new Date(res.data.last_replication_date).toLocaleDateString() + ' ' + new Date(res.data.last_replication_date).toLocaleTimeString([],{hour: '2-digit', minute:'2-digit'});
        }
      });
    },
  },
  watch: {
    dateFilter: {
      immediate:true,
      handler(newVal){
        if(newVal){
          const nextDay = new Date(newVal[1]);
          nextDay.setDate(newVal[1].getDate() + 1)

          let query = `project=${localStorage.getItem('current_project')}`
          query+= `&dateFrom=${this.formatDate(newVal[0])}&dateTo=${this.formatDate(nextDay)}`;  

          this.applyFilter(query)
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row mb-4">
      <div class="col-lg-12 mb-3">
        <div class="d-flex align-items-center">
          <div class="ms-3 flex-grow-1 mb-2">
            <h5 class="mb-0 card-title" style="font-size: 17px;">{{$t('dashboard.title')}}</h5>
            <p class="text-muted mb-0">{{$t('dashboard.subtitle')}}</p>
          </div>
          <div class="text-end w-50">
            <div class="row">
              <div class="col d-flex justify-content-end pe-0" >
                <ul class="nav nav-pills product-view-nav interact" v-if="!campaignId" v-on:click="onFilterClicked">
                  <li class="nav-item">
                    <a class="nav-link active" href="javascript: void(0);">
                      <i class="bx bx-filter align-middle"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col">
                <!--<p class="mb-0 text-start">{{$t('reco_analyze.date_range')}}</p>-->
                <date-picker v-model="dateFilter" :shortcuts="shortcuts" placeholder="Selecioná la fecha" range :format="'DD.MM.YYYY'" :clearable="false" :disabled-date="(date) => date >= new Date() || date < new Date().setDate((new Date()).getDate() - 90)">
                </date-picker>    
              </div>
            </div>
          </div>
        </div>
        <div class="badge bg-primary m-1" v-for="f in filters" :key="f.key">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{ f.badge }}</span>
              <i class="fa fa-times interact " v-on:click="onRemoveFilter(f)"></i>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-2 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{$t('dashboard.total_events')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('dashboard.total_events_tooltip')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="totalEvents">{{parseCountReduced(totalEvents)}}</h3>
                  </div>
              </div>
              <div class="col-sm-2 text-center" >
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{$t('dashboard.completed_events')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('dashboard.events_completed_tooltip')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="completedEvents">{{parseCountReduced(completedEvents)}}</h3>
                  </div>
                </div>
              <div class="col-sm-2 text-center">
                <div>
                    <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                      {{$t('dashboard.total_minutes')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('dashboard.total_minutes_tooltip')"/>
                    </p>
                    <h3 class="mb-0" v-b-popover.hover.bottom="totalMinutes">{{parseCountReduced(totalMinutes)}}</h3>
                  </div>
              </div>
              <div class="col-sm-2 text-center" style="border-right-style: solid; border-right-color: lightgray;">
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{$t('dashboard.avg_minutes')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('dashboard.avg_minutes_tooltip')"/>
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="totalMinutes">{{parseCountReduced(avgMinutes)}}</h3>
                </div>
            </div>
            <div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{$t('personal_analyze.conversion_rate')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.bottom="$t('dashboard.conversion_rate_tooltip')"/>
                  </p>
                  <h3 class="mb-0">{{`-`}}</h3>
                </div>
            </div>
            <div class="col-sm-2 text-center" >
                <div>
                  <p class="text-muted text-truncate mb-0 d-inline-flex font-size-13">
                    {{$t('personal_analyze.revenue')}}<i class="mdi mdi-help-circle-outline ms-1 text-primary interact" v-b-popover.hover.left="$t('dashboard.revenue_tooltip')"/>
                  </p>
                  <h3 class="mb-0" v-b-popover.hover.bottom="123">{{`-` }}</h3>
                </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div class="col-xl-12">
        <Earnings :filter="query" :total_events = "totalEvents" />
      </div>
      <Drawer
      @close="rightDrawer"
      :align="'right'"
      :closeable="true"
      :maskClosable="true"
      :zIndex="1002"
      class="h-100">
          <div v-if="openRightDrawer" class="h-100">
              <div class="offcanvas-header">
                  <h4 class="offcanvas-title" id="offcanvasExampleLabel">
                  <i class="bx bx-gear font-size-16 align-middle me-1"></i>
                  {{$t('filter.title')}}
                  </h4>
              </div>
              <hr class="mt-1" />
              <div class="offcanvas-body flex-grow-1 h-100">
                <div>
                  <FilterC ref="filterRef"></FilterC>
                </div>
                <div class="position-fixed bottom-0 end-0 p-3">
                  <button class="btn btn-secondary me-2" @click="removeAllFilter">{{$t('filter.clean')}}</button>
                  <button class="btn btn-primary me-2" @click="applyCustomFilter">{{$t('filter.apply')}}</button>
                </div>
              </div>
            </div>
    </Drawer>
    </div>
</Layout>
</template>

<style>
.mx-btn-shortcut{
  font-size: 13px !important;
  font-family: var(--bs-body-font-family);
  padding: 0 4px;
  color: var(--bs-primary)
}

.mx-datepicker-sidebar + .mx-datepicker-content {
    margin-left: 150px !important;
    border-left: 1px solid #e8e8e8;
}

.mx-datepicker-sidebar {
  width: 120px;
}

.cell.active{
  background-color: var(--bs-primary) !important;
}
</style>
