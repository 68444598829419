<script>
import Multiselect from "vue-multiselect";

import {
  campaignMethods,
} from "@/state/helpers";

export default {
  data() {
    return {
      goals: [
        //{ _id: 'all',name : this.$t('personal_analyze.goal_all')},
        { _id: 'purchase',name : this.$t('goals.purchase')},
        { _id: 'cart',name : this.$t('goals.cart')},
        { _id: 'like',name : this.$t('goals.like')},
        { _id: 'view',name : this.$t('goals.view')},
      ],
      auxGoal: null,

      eventTypes: [
        {id: "one_to_one", label: this.$t('dashboard.type_one_to_one')},
        {id: "one_to_few", label: this.$t('dashboard.type_one_to_few')},
        {id: "one_to_many", label: this.$t('dashboard.type_one_to_many')},
        
      ],
      auxEventType: null,

      campaigns:[],
      auxCampaign: null,
      filters:[]
    };
  },
  components:{Multiselect},
  props: {
    
  },
  mounted(){
    
  },
  computed: {
    
    
  },
  methods: {
    ...campaignMethods,

    loadCampaigns(){
      let loader = this.$loading.show();

      const params={
        q: `limit=200`,
      }

      params.q+= `&where[project]=${localStorage.getItem('current_project')}` 

      this.getCampaigns(params).then((campaigns)=>{
        if(campaigns&& campaigns.data){
          this.campaigns= campaigns.data;
          this.auxCampaign = this.filters.filter(f=> f.key== 'campaign').length > 0 ? this.campaigns.filter(g=> g._id == this.filters.filter(f=> f.key== 'campaign')[0].value)[0]  :null
      }
      }).catch(()=>{
        this.$notify({ type: 'error', text: this.$t("campaigns.get_campaigns_error"),title:  this.$t("campaigns.title") });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },

    removeAll(){
      this.auxGoal = null;
      this.auxEventType = null;
      this.auxCampaign = null;
    },
    getAllFilters(){
      const filters= [];
      if(this.auxGoal){
        filters.push({
          key: 'goal',
          value: this.auxGoal._id,
          badge: `${this.$t('filter.goal')}: ${this.auxGoal.name}`
        });
      }
      
      if(this.auxEventType){
        filters.push({
          key: 'eventType',
          value: this.auxEventType.id,
          badge: `${this.$t('filter.event_type')}: ${this.auxEventType.label}`
        });
      }

      if(this.auxCampaign){
        filters.push({
          key: 'campaign',
          value: this.auxCampaign._id,
          badge: `${this.$t('filter.campaign')}: ${this.auxCampaign.name}`
        });
      }
        
       
      return filters;
    },
    setAllFilter(filters){
      this.auxGoal = filters.filter(f=> f.key == 'goal').length > 0 ? this.goals.filter(g=> g._id == filters.filter(f=> f.key== 'goal')[0].value)[0]  :null;
      this.auxEventType = filters.filter(f=> f.key== 'eventType').length > 0 ? this.eventTypes.filter(g=> g.id == filters.filter(f=> f.key== 'eventType')[0].value)[0]  :null
      this.filters = filters;
      this.loadCampaigns();
    }
  },
  watch:{
    
  }
};
</script>

<template>
  <div class="flex-grow-1 h-100 accordion" role="tablist">
    <div class="mb-2">
      <div class="badge bg-primary m-1" v-if="auxGoal">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('filter.goal')}}: {{auxGoal?.name}}</span>
              <i v-on:click="auxGoal =null" class="fa fa-times interact "></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="auxEventType">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('filter.event_type')}}: {{auxEventType?.label}}</span>
              <i v-on:click="auxEventType =null" class="fa fa-times interact"></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="auxCampaign">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('filter.campaign')}}: {{auxCampaign?.name}}</span>
              <i v-on:click="auxCampaign =null" class="fa fa-times interact"></i>
          </div>
      </div>
    </div>
    <div class="mb-2">
        <div v-b-toggle="'accordion-0'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
          <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('filter.goal') }}</button>
          <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
        </div>
          <b-collapse :id="`accordion-0`" role="tabpanel" class="mt-2">
            <p class="small mb-1">{{ $t('filter.goal_subtitle') }}</p>
            <multiselect 
              :options="goals"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              track-by="_id" 
              label="name"
              :show-labels="false"
              :multiple="false"
              v-model="auxGoal"
              :allow-empty="false">
            </multiselect>
          </b-collapse>
    </div>
    <div class="mb-2">
        <div v-b-toggle="'accordion-1'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
          <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('filter.event_type')}}</button>
          <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
        </div>
          <b-collapse :id="`accordion-1`" role="tabpanel" class="mt-2">
            <p class="small mb-1">{{ $t('filter.event_type_subtitle') }}</p>
            <multiselect 
              :options="eventTypes"
              :selectLabel="$t('common.select')"
              :deselectLabel="$t('common.deselect')"
              track-by="id" 
              label="label"
              :show-labels="false"
              :multiple="false"
              v-model="auxEventType"
              :allow-empty="false">
            </multiselect>
          </b-collapse>
    </div>
    <div class="mb-2">
      <div v-b-toggle="'accordion-3'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
        <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('filter.campaign')}}</button>
        <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
      </div>
        <b-collapse :id="`accordion-3`" role="tabpanel" class="mt-2">
          <p class="small mb-1">{{$t('filter.campaign_subtitle')}}</p>
          <multiselect 
            :options="campaigns"
            :selectLabel="$t('common.select')"
            :deselectLabel="$t('common.deselect')"
            track-by="_id" 
            label="name"
            :show-labels="false"
            :multiple="false"
            v-model="auxCampaign"
            :allow-empty="false">
          </multiselect>
        </b-collapse>
    </div>
  </div>
</template>
